import React from "react";
import { Link } from 'gatsby';

export default function Nav() {
	return (
		<div className='nav'>
			<div className='nav__container'>
				<Link to='/'><div className='nav__logo'>unDraw <span className='nav__logo--stress'>Creatives</span></div></Link>
				<div className='nav__menu'>
					<a href='https://twitter.com/unDraw_co' target='_blank' rel='noreferrer noopener'><span className='nav__menu__item'>Twitter</span></a>
					<a href='/feed/rss'><span className='nav__menu__item'>RSS</span></a>
					<a href='https://undraw.co/'><button className='nav__menu__btn'>unDraw</button></a>
				</div>
			</div>
		</div>
	)
}
