import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
const siteUrl = "https://blog.undraw.co";

const SEO = ({ title, description, image, pathname, article, twitterUsername }) => {
	const seo = {
		title,
		description,
		image: image.includes('https://') ? image : `${siteUrl}${image}`,
		url: `${siteUrl}${pathname}`,
		twitterUsername,
		article
	}
	return (
		<Fragment>
		<Helmet title={seo.title}>
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />
			{seo.url && <meta property="og:url" content={seo.url} />}
			{(article ? true : null) && (
			<meta property="og:type" content="article" />
			)}
			{seo.title && <meta property="og:title" content={seo.title} />}
			{seo.description && (
			<meta property="og:description" content={seo.description} />
			)}
			{seo.image && <meta property="og:image" content={seo.image} />}
			<meta name="twitter:card" content="summary_large_image" />
			{seo.twitterUsername && seo.article && (
			<meta name="twitter:creator" content={seo.twitterUsername} />
			)}
			{seo.title && <meta name="twitter:title" content={seo.title} />}
			{seo.description && (
			<meta name="twitter:description" content={seo.description} />
			)}
			{seo.image && <meta name="twitter:image" content={seo.image} />}
		</Helmet>
		</Fragment>
	)
}

export default SEO;

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	pathname: PropTypes.string,
	article: PropTypes.bool,
	twitterUsername: PropTypes.string
}

SEO.defaultProps = {
	title: "",
	description: "",
	image: "/undraw_creatives_social_2.png",
	pathname: "",
	article: false,
	twitterUsername: "@unDraw_co"
}